<template>
  <footer class="footer navbar-fixed-bottom " style="height: 55px;width: 100vw">
    <div class="container" style="padding: 0;">
      <div class="page">
        <div class="page__bd"
             style="height: 100%;">
          <div class="weui-tab">
            <div class="weui-tab__panel">
            </div>
            <div class="weui-tabbar">
              <!--<div class="weui-tabbar__item " v-bind:class="{'weui-bar__item_on': name === 'basketball'}"  @click="jump('basketball')">-->
                <!--<div style="display: inline-block; position: relative;">-->
                  <!--<img :src="basketballIMG"-->
                       <!--alt=""-->
                       <!--class="weui-tabbar__icon">-->
                <!--</div>-->
                <!--<p class="weui-tabbar__label">篮球场</p>-->
              <!--</div>-->
              <div class="weui-tabbar__item" v-bind:class="{'weui-bar__item_on': name === 'tennis'}" @click="jump('tennis')">
                <img :src="tennisIMG"
                     alt=""
                     class="weui-tabbar__icon">
                <p class="weui-tabbar__label">网球场</p>
              </div>
              <div class="weui-tabbar__item " v-bind:class="{'weui-bar__item_on': name === 'user'}" @click="jump('user')">
                <img :src="userIMG"
                     alt=""
                     class="weui-tabbar__icon">
                <p class="weui-tabbar__label">我的</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import basketballIMG from '@/assets/image/basketball.png'
import tennisIMG from '@/assets/image/tennis.png'
import userIMG from '@/assets/image/user.png'
export default {
  name: 'foot',
  data () {
    return {
      name: sessionStorage.getItem('tabbar') == null?'basketball':sessionStorage.getItem('tabbar'),
      basketballIMG: basketballIMG,
      tennisIMG: tennisIMG,
      userIMG: userIMG
    }
  },
  methods: {
    jump (name) {
      sessionStorage.setItem('tabbar',name)
      this.$router.push('/'+name)
    }
  }
}
</script>

<style scoped>

</style>
