<template>
  <div class="js_dialog"
       v-show="showDialog">
    <div class="weui-mask"></div>
    <div class="weui-dialog">
      <div class="weui-dialog__bd">{{dialogText}}</div>
      <div class="weui-dialog__ft">
        <a @click="close" class="weui-dialog__btn weui-dialog__btn_default">取消订单</a>
        <a @click="toPay" class="weui-dialog__btn weui-dialog__btn_primary">现在支付</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'dialog',
    data() {
      return {
        showDialog: false,
        dialogText: '',
        ooO: '',
      }
    },
    methods: {
      close(){
        const that = this
        that.$axios.get(that.$url + 'usercancel?ooO=' + that.ooO)
          .then(function (res) {
            if (res.data === true) {
              that.min = 14
              that.sec = 60
              that.$router.push('/')
            } else {
              that.$refs.wn.warnText = '系统出现错误'
              that.$refs.wn.showWarn = true
              setTimeout(function () {
                that.$refs.wn.showWarn = false
                this.showDialog = false
              }, 1000)
            }
          })

        // this.$parent.$refs.of.showOrderForm = true
      },
      toPay(){
        sessionStorage.setItem('ooO', this.ooO)
        this.$router.push('/pay')
      }
    }
  }
</script>

<style scoped>

</style>
