<template>
  <div class="container-fluid" v-show="!showImg">
    <div class="row" style="padding-left: 1%;padding-right: 1%">
      <div class="col-xs-12 d">
        <img src="../assets/image/dylogo.png" style="width: 30vw;height: 40vw">
      </div>
      <div class="col-xs-12 ">
        <h3 style="text-align: center;font-weight: bolder">团购自助验券</h3>
      </div>
      <div class="col-xs-12 d" style="margin-top: 30px">
        <input v-model="code" placeholder="请输入券码" style="border: none;width: 60vw;outline: none;"
               type="text">
      </div>
      <div class="col-xs-12 d">
        <div style=" border-top: 1px solid #ccc;
  margin: 10px 0;width: 60vw"></div>
      </div>
      <div class="col-xs-12 d" style="margin-top: 3vh">
        <button class="btn btn-info" style="width: 60vw;height: 5vh;font-size: medium;font-weight: bolder" type="button" @click="s">确认</button>
      </div>
      <div class="col-xs-12" style="text-align: center;margin-top: 3vh">
        <button class="btn btn-warning" style="width: 30vw;height: 8vw;font-size: medium;font-weight: bolder" type="button" @click="back" >返回</button>
      </div>
      <div class="col-xs-12" style="text-align: center">
        <div class="weui-loadmore weui-loadmore_line" style="margin-top: 3vh">
          <span class="weui-loadmore__tips"></span>
        </div>
        <span >温馨提示:只支持抖音30分钟单次团购券自助核销，如需兑换其他团购券请联系客服，客服热线：<a href="tel:13326826235">13326826235</a></span>
        <h4>自助核销流程图</h4>
      </div>
      <div class="col-xs-12" style="display: flex;align-items: center;justify-content: center;height: 15vh;margin-top: 1vh;position: relative;" @click="showImg2(true)">
        <img src="../assets/image/dylc.png" style="width: 15vh;height: 15vh">
        <img src="../assets/image/fangdajing.png" style="position: absolute;margin: 0 auto;width: 7vh;height: 7vh;opacity: 0.5">

      </div>
      <loading ref="lg"></loading>
      <warn ref="wn"></warn>
      <dyv ref="dyv"></dyv>
      <loading ref="load"></loading>
      <foot></foot>
    </div>
  </div>
  <div v-show="showL2">
    <div class="weui-mask_transparent"></div>
    <div class="weui-toast">
            <span class="weui-primary-loading weui-icon_toast">
              <span class="weui-primary-loading__dot"></span>
            </span>
      <p class="weui-toast__content">正在检查券是否有效</p>
    </div>
  </div>
  <div v-show="showImg" @click="showImg2(false)" style="position: relative;height: 100vh;width: 100vw">
    <div class="weui-mask"></div>
    <div style="z-index: 1010;position: absolute;top: 0;left: 0;right: 0;bottom: 0;margin: auto;">
      <img src="../assets/image/dylc.png" style="width: 100%;height: 100%">
    </div>
  </div>
</template>

<script>
import Warn from '@/components/modular/warn'
import Loading from '@/components/modular/loading'
import Foot from '@/views/foot'
import Dyv from '@/components/modular/dyv.vue'
import load from '@/components/modular/loading.vue'

export default {
  name: 'dy',
  components: {
    Dyv,
    Warn,
    Foot,
    Loading
  },
  data () {
    return {
      rid: sessionStorage.getItem('rid'),
      code: '',
      ready: false,
      showL2: false,
      showImg: false,
    }
  },
  created () {
    const that = this

  },
  methods: {
    back() {
      this.$router.push('/user')
    },
    showImg2(flag){
      this.showImg = flag
    },
    s () {
      const that = this
      if (this.code != null) {
        this.$axios.get(this.$url + '/dy/preCode?code=' + this.code + '')
          .then(function (res) {
            console.log(res.data.data)
            let data = res.data.data
            if (data.error_code == 0) {
              if (data.certificates.length > 0) {
                that.$refs.dyv.code = data.certificates[0].encrypted_code
                that.$refs.dyv.token = data.verify_token
                let name = data.certificates[0].sku.title
                // if (name.indexOf('教练') === -1) {
                  if (name.indexOf("30分钟") !== -1 && name.indexOf("教练") === -1 && name.indexOf("2次") === -1) {
                  // that.showL2 = true
                  // setTimeout(function () {
                  //   that.showL2 = false
                    that.$refs.dyv.show = true
                  // }, 2500)
                } else {
                  that.$refs.wn.warnText = '该券需要联系客服进行兑换！'
                  that.$refs.wn.showWarn = true
                  setTimeout(function () {
                    that.$refs.wn.showWarn = false
                  }, 1000)
                }
              }else {
                that.$refs.wn.warnText = '该优惠券已被使用'
                that.$refs.wn.showWarn = true
                setTimeout(function () {
                  that.$refs.wn.showWarn = false
                }, 1000)
              }
            } else {
              that.$refs.wn.warnText = '请输入正确券码'
              that.$refs.wn.showWarn = true
              setTimeout(function () {
                that.$refs.wn.showWarn = false
              }, 1000)
            }

          })
      }
    }
  }
}
</script>

<style>
.d {
  display: flex;
  justify-content: center;
  align-items: center;
}

input::placeholder {
  text-align: center;
}
</style>
