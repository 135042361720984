<template>
  <div v-show="showOrder">
    <div class="weui-mask"></div>
    <div class="weui-dialog"
         style="border-radius: 20px;">
      <button @click="closeOrder"
              type="button"
              class="close"
              aria-hidden="true"
              style="position: absolute;left: 90%;top:0">&times;
      </button>
      <h4 style="font-weight: bolder">订单详情</h4>
      <div class="weui-form-preview">
        <div class="weui-form-preview__bd">
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">微信头像</label>
            <span class="weui-form-preview__value">
                                <img class="img-circle img" :src="imgUrl">
                            </span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">微信昵称</label>
            <span class="weui-form-preview__value">{{nickname}}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">订单号</label>
            <span class="weui-form-preview__value">{{order.oid}}</span>
          </div>
<!--          <div class="weui-form-preview__item">-->
<!--            <label class="weui-form-preview__label">联系人姓名</label>-->
<!--            <span class="weui-form-preview__value">{{order.name}}</span>-->
<!--          </div>-->
<!--          <div class="weui-form-preview__item">-->
<!--            <label class="weui-form-preview__label">队伍名称</label>-->
<!--            <span class="weui-form-preview__value">{{order.teamName}}</span>-->
<!--          </div>-->
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">联系电话</label>
            <span class="weui-form-preview__value">{{order.phone}}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">订单金额</label>
            <span class="weui-form-preview__value">{{order.money === 0?'免费':order.money+'元'}}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">场外消费</label>
            <span class="weui-form-preview__value">{{order.water === 1?'45元':'无'}}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">优惠折扣</label>
            <span class="weui-form-preview__value">{{order.discount === 0?'无优惠':order.discount+'折'}}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">实付金额</label>
            <span class="weui-form-preview__value">{{order.dmoney === 0?'免费':order.dmoney+'元'}}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">支付时间</label>
            <span class="weui-form-preview__value">{{timestampTotime(order.time)}}</span>
          </div>
          <a @click="cancelOrder"
             class="weui-btn weui-btn_warn">取消订单</a>
        </div>
      </div>
    </div>
  </div>
  <div class="js_dialog" role="dialog"  aria-hidden="true" aria-modal="true" id="iosDialog2" v-show="showCancel">
    <div class="weui-mask"></div>
    <div class="weui-dialog">
      <div class="weui-dialog__bd">您确定要取消此订单吗</div>
      <div class="weui-dialog__ft">
        <a role="button" @click="cancel2" class="weui-dialog__btn weui-dialog__btn_warn">确定</a>
        <a role="button" @click="back" class="weui-dialog__btn weui-dialog__btn_default">返回</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'showOrder',
  data() {
    return {
      oid: '',
      order: [],
      showOrder: false,
      showCancel: false,
      imgUrl: '',
      nickname: ''
    }
  },
  methods: {
    back(){
      this.showCancel =false
      this.showOrder = true
    },
    cancel2(){
      const that = this
      that.$axios.post(that.$url + '/out','oid='+that.oid+'&rid='+sessionStorage.getItem('rid'))
        .then(function (res) {
          let data = res.data
          if (data.flag === true) {
            that.$parent.$refs.toast.showToast = true
            setTimeout(function () {
              that.$parent.$refs.toast.showToast = false
              that.closeOrder()
              that.showCancel = false
              that.$parent.findBall()
            },2000)
          }else {
            that.$parent.$refs.warn.warnText = data.Msg
            that.$parent.$refs.warn.showWarn = true
            setTimeout(function () {
              that.$parent.$refs.warn.showWarn = false
              that.showCancel = false
            },2000)
          }
        })
    },
    cancelOrder () {
      // 取消订单

      this.showOrder = false
      this.showCancel = true
    },
    closeOrder () {
      this.showOrder = false
    },
    timestampTotime (time) {
      if (time != null){
        const date = new Date(time + 8 * 3600 * 1000)
        return date.toJSON().substr(0, 19).replace('T', ' ').replace(/-/g, '.')
      }
    },
  }
}
</script>

<style scoped>
.img{
  width: 30px;
  height: 30px;
}
</style>
