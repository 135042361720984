<template>
  <div class="page">
    <div class="page__hd">
      <h1 class="page__title">订单详情</h1>
      <p class="page__desc">有疑问请联系客服,客服热线：<a href="tel:13326826235">13326826235</a></p>
    </div>
    <div class="page__bd">
      <div class="weui-form-preview">
        <div class="weui-form-preview__hd">
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">付款金额</label>
            <em class="weui-form-preview__value">{{dmoney}}</em>
          </div>
        </div>
        <div class="weui-form-preview__bd">
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">微信头像</label>
            <img :src="headimgurl" class="img-circle">
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">微信昵称</label>
            <span class="weui-form-preview__value">{{nickname}}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">商品</label>
            <span class="weui-form-preview__value">{{court}}</span>
          </div>
          <img v-show="order.pay === 0" class="payIMG"
               :src="successIMG">
          <img v-show="order.pay === 2" class="payIMG"
               :src="failIMG">
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">订单号</label>
            <span class="weui-form-preview__value">{{order.wxNo}}</span>
          </div>
          <div class="weui-form-preview__item" v-for="(ot,index) in orderTime">
            <label class="weui-form-preview__label">场次{{index + 1}}</label>
            <span class="weui-form-preview__value" style="font-weight: bolder" >{{timestampTotime(ot.begintime)}} - {{timestampTotime(ot.endtime)}}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">总计时间</label>
            <span class="weui-form-preview__value">{{(order.hour)/2}}小时</span>
          </div>
<!--          <div class="weui-form-preview__item">-->
<!--            <label class="weui-form-preview__label">是否购买纯净水</label>-->
<!--            <span class="weui-form-preview__value">{{water}}</span>-->
<!--          </div>-->
<!--          <div class="weui-form-preview__item">-->
<!--            <label class="weui-form-preview__label">是否需要裁判服务</label>-->
<!--            <span class="weui-form-preview__value">{{referee}}</span>-->
<!--          </div>-->
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">订单金额</label>
            <span class="weui-form-preview__value">{{order.money}}元</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">会员优惠折扣</label>
            <span class="weui-form-preview__value">{{discount}}</span>
          </div>
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">优惠券折扣</label>
            <span class="weui-form-preview__value">{{freel}}</span>
          </div>
<!--          <div class="weui-form-preview__item">-->
<!--            <label class="weui-form-preview__label">联系人</label>-->
<!--            <span class="weui-form-preview__value">{{order.name}}</span>-->
<!--          </div>-->
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">联系人电话</label>
            <span class="weui-form-preview__value">{{order.phone}}</span>
          </div>
<!--          <div class="weui-form-preview__item">-->
<!--            <label class="weui-form-preview__label">队伍名称</label>-->
<!--            <span class="weui-form-preview__value">{{order.teamName}}</span>-->
<!--          </div>-->
          <div class="weui-form-preview__item">
            <label class="weui-form-preview__label">支付时间</label>
            <span class="weui-form-preview__value">{{timestampTotime(order.time)}}</span>
          </div>
        </div>
      </div>
      <h4 style="text-align: center"
          id="back">
        <button  type="button" @click="this.$router.push('/orderList')"
                 class="btn btn-warning" style="width: 30vw;height: 8vw;font-size: medium;font-weight: bolder">返回上一页</button>
      </h4>
    </div>
  </div>
  <loading ref="lg"></loading>
</template>

<script>
  import success from '@/assets/image/已完成.png';
  import fail from '@/assets/image/已取消.png';
  import Loading from '@/components/modular/loading';
  import $ from 'jquery';
  export default {
    name: 'order',
    components: { Loading },
    data() {
      return {
        oid: '',
        rid: sessionStorage.getItem('rid'),
        successIMG: success,
        failIMG: fail,
        dmoney: '免费',
        order: [],
        discount: '无优惠',
        freel:'无优惠',
        water: '否',
        referee: '否',
        court: '',
        headimgurl: '',
        nickname: '',
        orderTime: []
      };
    },
    created () {
      this.oid = this.$route.query.oid
      const that = this
      this.$axios.post(this.$url + 'findOrderByID','OID='+this.oid+'&RID='+this.rid)
        .then(function (res){
          let order = res.data
          if (order != null){
            that.order = order
            that.headimgurl = order.wxUser.headimgurl
            that.nickname = order.wxUser.nickname
            if (order.court === 'basketball'){
              that.court = '室内篮球场'
            }else {
              that.court = '室内网球场'
            }
            if (order.dmoney !== 0){
              that.dmoney = order.dmoney + '元'
            }
            if (order.water !== 0){
              that.water = '是'
            }
            if (order.discount !== 0){
              that.discount = order.discount + '折'
            }
            that.$axios.get(that.$url+'dy/findDyCardByOid?oid='+order.oid)
              .then(function (res2) {
              if (res2 !== null) {
                if (res2.data.length > 0) {
                  that.freel = '减免'+ res2.data.length + '个场次'
                }
              }
            })
            that.$axios.get(that.$url + 'findOrderTime?ooO='+order.wxNo)
              .then(function (res1) {
                that.orderTime = res1.data.sort(that.compare('begintime'))
              })
          }
        })
    },
    methods:{
      timestampTotime(time) {
        if (time != null) {
          let date = new Date(time + 8 * 3600 * 1000);
          return date.toJSON().substr(0, 19).replace('T', ' ').replace(/-/g, '.');
        }
      },
      compare(p){ //这是比较函数
        return function(m,n){
          let a = m[p];
          let b = n[p];
          return a - b; //升序
        }
      },
    }
  };
</script>

<style scoped>
.img-circle {
  width:50px;
  height:50px;
}
.payIMG {
  z-index: 5;
  position: absolute;
  left: 40%;
  top: 50%;
  width:150px;
  height:150px;
}
</style>
