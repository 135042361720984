import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import weuiCSS from 'weui';
import weuiJS from 'weui.js';
import bootstrapCSS from 'bootstrap/dist/css/bootstrap.min.css'
import bootstrapJS from 'bootstrap/dist/js/bootstrap.min'
import axios from 'axios'
import wx from 'weixin-js-sdk'
axios.defaults.withCredentials = true

const url = 'http://booking.cacloud.xyz/Abookings/'
// const url = 'https://54tlbc449557.vicp.fun/Abookings/'
const app = createApp(App)
app.config.globalProperties.$url = url
app.config.globalProperties.$axios = axios
app.use(router)
app.mount('#app')
app.component(wx)
app.component(bootstrapCSS)
app.component(bootstrapJS)
app.component(weuiCSS)
app.component(weuiJS)
app.component(axios)
app.use(store)
