<template>
  <div class="container-fluid"
       style="overflow: auto;height: calc(100vh)">
    <div class="row" style="overflow: hidden;">
      <p style="font-size: medium">点击拨打客服热线：
        <a href="tel:13326826235"
           style="font-weight: bold;color: red">13326826235</a>
      </p>
      <div class="col-xs-12" style="margin: 0;padding: 0;height: 70vh;overflow-y: auto;overflow-x: auto">
        <ul style="width: 100%;">
          <li id="weekList">
            <div v-for="(val,index) in dateList">
              <p v-html="val.date"></p>
            </div>
          </li>
          <li v-for=" x in ballNumber" class="weekList">
            <div v-for="(val,index) in dateList">
              <button :id="(index+1)+''+x" class="btn btn-default "
                      type="button"
                      v-bind:class="[{'btn-success' : getBall(x-1,index) != null},{'btn-danger' : ((x <= (hour*2) || (min > 30 && x <= (hour*2)+1)) && index === 0)}]"
                      v-bind:disabled="admin == false && getBall(x-1,index) != null  || ((x <= (hour*2) || (min > 30 && x <= (hour*2)+1)) && index === 0)"
                      @click="getBall(x-1,index) == null?cpick($event):admin?showOrder(getBall(x-1,index)):cpick"
                      v-html="getBall(x-1,index) || ((x <= (hour*2) || (min > 30 && x <= (hour*2)+1)) && index === 0) ? getPrice(x-1,index,false) : getPrice(x-1,index,true)"></button>
            </div>
          </li>
        </ul>
      </div>
      <div class=" col-xs-8"
           style="display: flex;align-items: center;justify-content: center;flex-direction:column;height: 13vh">
        <div>
          <button class="btn"
                  disabled
                  style="color: #fff;background-color: #5cb85c;border-color: #4cae4c;margin-right: 2vw"
                  type="button"></button>
          <span style="font-size: medium;margin-right: 2vw">已售出</span>

          <button class="btn"
                  disabled
                  style="color: #fff;background-color: #337ab7;border-color: #2e6da4;margin-right: 2vw"
                  type="button"></button>
          <span style="font-size: medium">已选中</span>
        </div>
        <div>
          <button class="btn btn-danger" disabled
                  style="margin-right: 2vw" type="button"></button>
          <span style="font-size: medium;margin-right: 2vw">已售罄</span>

          <button class="btn btn-default" disabled
                  style="margin-right: 2vw" type="button"></button>
          <span style="font-size: medium">可购买</span>
        </div>
      </div>
      <div class=" col-xs-4"
           style="display: flex;align-items: center;justify-content: center;flex-direction: column;height: 13vh">
        <button class="btn btn-warning" style="margin-bottom: 1vh" type="button" @click="cancel()"
        >全选取消
        </button>
        <button class="btn btn-success" type="button" @click="submit()"
        >确定提交
        </button>
      </div>
      <order-form ref="of"></order-form>
      <tips ref="tp"></tips>
      <loading ref="load"></loading>
      <show-order ref="so"></show-order>
      <statement></statement>
      <toast ref="toast"></toast>
      <payTips ref="dl"></payTips>
      <warn ref="warn"></warn>
    </div>
  </div>
  <foot></foot>
</template>

<script>
import '@/assets/weUIv2.4.0/style/weui.css'
import Loading from '@/components/modular/loading'
import ShowOrder from '@/components/modular/showOrder'
import Toast from '@/components/modular/toast'
import Warn from '@/components/modular/warn'
import Statement from '@/components/modular/statement'
import Foot from '@/views/foot'
import Tips from '@/components/modular/tips'
import OrderForm from '@/components/modular/orderForm'
import PayTips from '@/components/modular/payTips'

// eslint-disable-next-line no-unused-lets
export default {
  name: 'Tennis',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    OrderForm,
    Tips,
    Statement,
    Warn,
    Toast,
    ShowOrder,
    Loading,
    Foot,
    PayTips
  },
  data () {
    return {
      // rid: sessionStorage.getItem('rid')
      dayList: [],
      btnPick: false,
      pickList: [],
      dateList: [],
      priceList: [],
      ballList: [],
      rid: '',
      ball: [],
      year: 0,
      month: 0,
      day: 0,
      hour: 0,
      min: 0,
      sec: 0,
      admin: false,
      ballNumber: 0,
      timeText: '<span style=\'font-weight: bold\'>当前时间:</span>&nbsp;&nbsp;',
      arr: ['one', 'one1', 'two', 'two1', 'three', 'three1', 'four', 'four1', 'five', 'five1', 'six', 'six1',
        'seven', 'seven1', 'eight', 'eight1', 'nine', 'nine1', 'ten', 'ten1', 'eleven', 'eleven1', 'twelve', 'twelve1', 'thirteen', 'thirteen1',
        'fourteen', 'fourteen1', 'fifteen', 'fifteen1', 'sixteen', 'sixteen1', 'seventeen', 'seventeen1', 'eighteen', 'eighteen1',
        'nineteen', 'nineteen1', 'twenty', 'twenty1', 'twentyOne', 'twentyOne1', 'twentyTwo', 'twentyTwo1', 'twentyThree', 'twentyThree1', 'twentyFour', 'twentyFour1']
    }
  },
  created () {
    const that = this
    if (sessionStorage.getItem('rid') === null) {
      that.$router.push('/')
    } else {
      that.rid = sessionStorage.getItem('rid')

    }
    that.findBall()
    // setTimeout(function (){
    //   that.findHeadImg()
    // },500)

    const read = sessionStorage.getItem('read')
    if (read != null) {
      this.findCacheOrder(false)
    }
  },
  mounted () {
    const that = this
    document.body.onselectstart = document.body.ondrag = function () {
      return false
    }
    this.timeCount()
    setTimeout(function (){
      let time1 = new Date().getHours() * 2
      if (new Date().getMinutes() > 30) {
        time1 = time1 + 1
      }
        var elementById = document.getElementById("1"+time1)
      if (elementById ) {
          elementById.scrollIntoView()
        }
      that.findHeadImg()
    },500)

  },
  // beforeUpdate() {

  // },
  methods: {
    findHeadImg() {
      const that = this
      let idList = []
     $(".headImg").each(function(){
       idList.push($(this).attr('id'))
     })
      console.log(idList)
      for (let i = 0; i < idList.length; i++) {
        let oid = idList[i].substring(1,idList[i].length)
        that.$axios.get(that.$url + 'findHeadImgByOid?OID=' + oid).then(function (res){
          let headImg = res.data
          $("."+idList[i]).attr('src',headImg)
        })
      }

    },
    getValueCount (obj) {
      return Object.values(obj).length
    },
    findBall () {
      const that = this
      this.$axios.post(this.$url + 'tennisall')
        .then(function (res) {
          that.ball = res.data
          that.ballNumber = that.getValueCount(res.data[0]) - 4
          that.$axios.post(that.$url + 'findAdministratorsOfRole', 'rid=' + that.rid)
            .then(function (resf) {
              that.admin = resf.data
              that.dateList = []
              that.ballList = []
              that.priceList = []
              for (let i = 0; i < that.ball.length; i++) {
                // let basketball = data[i];
                let year = that.ball[i].year
                let month = that.ball[i].month
                let day = that.ball[i].day
                let date = month + '.' + day + '<br>' + that.findweek(year, (month - 1), day)
                let price = that.ball[i].price
                let ball = that.ball[i]
                that.dateList.push({
                  date: date,
                  price: price.one,
                  ball: ball.one
                })
                that.ballList.push({
                  price: price.one1,
                  ball: ball.one1
                })
                that.priceList.push(price)
              }
            })
        })
    },
    flag () {
      const that = this
      let flag = true
      for (let x in this.dayList) {
        let day = this.dayList[x]
        let index = 0
        for (let i in that.pickList) {
          let date = that.pickList[i].substring(0, 1)
          if (date === day) {
            index++
          }
        }
        if (index === 1) {
          flag = false
          break
        }
      }
      return flag
    },
    showOrder (oid) {
      const that = this
      this.$axios.post(that.$url + 'findOrderByID', 'OID=' + oid + '&RID=' + this.rid)
        .then(function (res) {
          // console.log(res)
          let order = res.data
          that.$refs.so.order = order
          that.$refs.so.oid = order.oid
          that.$refs.so.imgUrl = order.wxUser.headimgurl
          that.$refs.so.nickname = order.wxUser.nickname
          that.$refs.so.showOrder = true
        })
    },
    getBall (number, day) {
      const that = this
      let ball = that.ball[day]
      for (let key in ball) {
        if (that.arr[number] === key) {
          return ball[key]
        }
      }
    },
    getPrice (number, day, flag) {
      const that = this

      let time = ''
      if (number > 1) {
        if (number % 2 === 0) {
          time = number / 2 + ':00-' + (number / 2) + ':30'
        } else {
          time = (number - 1) / 2 + ':30-' + ((number - 1) / 2 + 1) + ':00'
        }
      } else {
        if (number != 1) {
          time = '0:00-0:30'
        } else {
          time = '0:30-1:00'
        }
      }

      if (that.getBall(number, day)) {
        // if (that.admin === true) {
        let oid = that.getBall(number, day)
        return '<img class="headImg img-circle o'+oid+'" id="o'+oid+'" style="height: 28px;width: 28px;margin-right: 1vw"   alt="">已预订</br>' + time
      } else {
        let list = that.priceList[day]
        for (let key in list) {
          if (that.arr[number] === key) {
            if (flag) {
              return '￥<span style="font-size: 20px;font-weight: bold">' + list[key] + '</span></br>' + time
            } else {
              return '已售罄</br>' + time
            }
          }
        }
      }

    },
    cpick (event) {

      const target = event.target
      const that = this
      let id = target.id
      if (this.pickList.length > 0) {
        let index = this.pickList.indexOf(id)
        let day = id.substring(0, 1)
        let time = Number(id.substring(1))
        if (index !== -1) {
          let maxAndmin2 = this.maxAndmin(day)
          if (maxAndmin2[0] === time || maxAndmin2[1] === time) {
            this.pickList.splice(index, 1)
            target.classList.remove('btn-primary')
          } else {
            this.$refs.warn.showWarn = true
            this.$refs.warn.warnText = '只能连续订场'
            setTimeout(function () {
              that.$refs.warn.showWarn = false
            }, 1000)
          }
        } else {
          let x = this.pickList.length
          this.pickList[x] = id
          target.classList.add('btn-primary')
          let index1 = this.dayList.indexOf(day)
          if (index1 !== -1) {
            let maxAndmin3 = this.maxAndmin(day)
            for (let y = maxAndmin3[0]; y < maxAndmin3[1]; y++) {
              let dom = document.getElementById(day + '' + y)
              if (dom.className.indexOf('btn-primary') < 0) {
                let x1 = this.pickList.length
                this.pickList[x1] = dom.getAttribute('id')
                dom.classList.add('btn-primary')
              }
            }
          } else {
            let length = this.dayList.length
            this.dayList[length] = day
          }
          // console.log(this.dayList)
        }
      } else {
        let x = this.pickList.length
        this.pickList[x] = id
        let day = id.substring(0, 1)
        this.dayList[this.dayList.length] = day
        target.classList.add('btn-primary')
      }

    },
    cancel () {
      let sm = document.getElementsByClassName('btn')
      for (let i = 0; i < sm.length; i++) {
        if (sm[i] != null) {
          sm[i].classList.remove('btn-primary')
        }
      }
      this.pickList.length = 0
      this.dayList.length = 0
    },
    maxAndmin (day) {
      let max = 1
      let min = 48
      for (let i in this.pickList) {
        if (this.pickList[i].substring(0, 1) === day) {
          let s = parseInt(this.pickList[i].substring(1))
          if (s > max) {
            max = s
          }
          if (s < min) {
            min = s
          }
        }
      }
      return [min, max]
    },
    findweek (year, month, day) {
      const dt = new Date(year, month, day)
      const weekDay = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      // console.log(weekDay[dt.getDay()])
      return weekDay[dt.getDay()]
    },
    findCacheOrder (flag) {
      const that = this
      that.$axios.get(that.$url + 'findCacheOrder?rid=' + that.rid)
        .then(function (res) {
          let data = res.data
          if (data === 'not') {
            if (flag) {
              that.$refs.of.court = 'tennis'
              that.$refs.of.showOrderForm = true
            }
          } else {
            that.$refs.dl.showDialog = true
            that.$refs.dl.ooO = data
            that.$refs.dl.dialogText = '您还有一笔未支付的订单已暂时帮您锁定场地，有效支付时间过后自动解锁，是否现在支付'
          }

        })
    },
    submit () {
      const that = this
      // eslint-disable-next-line eqeqeq
      if (that.rid != null) {
        if (that.pickList.length > 0) {
          // if (that.pickList.length > 1) {
          //   if (that.flag()) {
          that.findCacheOrder(true)
          // }else {
          //   that.$refs.warn.showWarn = true
          //   that.$refs.warn.warnText = '不能一天1小时'
          //   setTimeout(function () {
          //     that.$refs.warn.showWarn = false
          //   }, 1000)
          // }
          // } else {
          //   that.$refs.warn.showWarn = true
          //   that.$refs.warn.warnText = '2小时起订'
          //   setTimeout(function () {
          //     that.$refs.warn.showWarn = false
          //   }, 1000)
          // }
        } else {
          that.$refs.warn.showWarn = true
          that.$refs.warn.warnText = '您还未选中任何场次'
          setTimeout(function () {
            that.$refs.warn.showWarn = false
          }, 1000)
        }
      } else {
        that.$refs.warn.showWarn = true
        that.$refs.warn.warnText = '登陆信息已过期，请重新进入'
        setTimeout(function () {
          that.$refs.warn.showWarn = false
        }, 1000)
      }
    },
    timeCount () {
      const that = this
      let date = new Date()
      this.year = date.getFullYear()
      this.month = date.getMonth() + 1
      this.day = date.getDate()
      this.hour = date.getHours()
      this.min = date.getMinutes()
      this.sec = date.getSeconds() + 1
      let j = 0
      if (this.hour > 2) {
        if (this.min < 31) {
          j = this.hour * 2
        } else {
          j = (this.hour * 2) + 1
        }
      } else {
        if (this.hour == 0) {
          if (this.min > 30) {
            j = 1
          }
        } else {
          if (this.min > 30) {
            j = 3
          } else {
            j = 2
          }
        }
      }

      // console.log(j)
      // for (let i = 0; i <= j; i++) {
      //   let id = '1' + i
      //   let dom = document.getElementById(id)
      //   if (dom != null) {
      //     dom.classList.remove('btn-default')
      //     dom.classList.add('btn-danger')
      //     dom.innerText = '已售完'
      //     dom.setAttribute('disabled', true)
      //   }
      // }
      // this.timeText = '<span style=\'font-weight: bold\'>当前时间:</span>&nbsp;&nbsp;<span style=\'color: violet;font-weight: bold\'>' + this.year + '</span>年<span style=\'color: green;font-weight: bold\'>' + this.month + '</span>月<span style=\'color: orange;font-weight: bold\'>' + this.day + '</span>日 ' + this.hour + ':' + this.min + ':' + this.sec + '</span>'
      setTimeout(function () {
        that.timeCount()
      }, 1000)
    },
    timestampTotime (time) {
      const date = new Date(time + 8 * 3600 * 1000)
      return date.toJSON().substr(0, 19).replace('T', ' ').replace(/-/g, '.')
    },
    getRid () {
      return this.rid
    }
  }
}
</script>

<style scoped>
#ballRow {
  width: 550px;
  padding-left: 40px;
  height: 100%;
}

#ballChoose {
  height: 60vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: auto;
  margin-top: 12px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.btn-danger {
  color: #fff;
  background-color: #666;
  border-color: #333;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #666;
  border-color: #333;
}

.btn-danger:hover {
  color: #fff;
  background-color: #666;
  border-color: #333;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #666;
  border-color: #333;
}

.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  color: #fff;
  background-color: #666;
  border-color: #333;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #666;
  border-color: #333;
}

.btn-danger .badge {
  color: #666;
  background-color: #fff;
}

h1, h2, h3 {
  text-align: center;
}

.btn-sm {
  height: 25px;
  width: 70px;
  margin-top: 3.5px;
  margin-right: 2px
}

.time {
  position: absolute;
  left: -40px;
  top: -10px;
  color: black;
}

.date {
  position: absolute;
  top: -40px;
  left: 10px;
}

.datef {
  position: relative;
  float: left;
}

.weui-mask {
  z-index: 3001;
}

.weekList {
  width: 100vw;
  white-space: nowrap;
  margin-top: 1vh;
}

.weekList div {
  width: 32vw;
  height: 15vw;
  display: inline-block;
  margin-left: 3vw;
  font-size: 30px;

}

.weekList div button {
  width: 100%;
  height: 100%;
  user-select: none;
}

#weekList {
  display: flex;
  width: 100%;
  height: 100%;
  position: sticky;
  top: -1vh;
  z-index: 20;
}

#weekList div {
  width: 32vw;
  height: 15vw;
  margin-left: 3vw;
  float: none;
  flex-shrink: 0;
  font-size: 30px;
  background: white;
}

#weekList div p {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}


</style>
