<template>
  <!--BEGIN dialog2-->

  <div class="js_dialog"
       v-show="show">
    <div class="weui-mask"></div>
    <div class="weui-dialog">
      <div class="weui-dialog__bd">券码有效，确定使用吗？</div>
      <div class="weui-dialog__ft">
        <a @click="close"
           class="weui-dialog__btn weui-dialog__btn_primary">取消</a>
        <a @click="valid"
           class="weui-dialog__btn weui-dialog__btn_primary">确定</a>
      </div>
    </div>
  </div>
  <!--END dialog2-->
  <!--BEGIN toast-->
  <div role="alert" id="toast" style="display: none;">
    <div class="weui-mask_transparent"></div>
    <div class="weui-toast">
      <i class="weui-icon-success-no-circle weui-icon_toast"></i>
      <p class="weui-toast__content">成功核销！卡券已添加到个人卡包</p>
    </div>
  </div>
  <div role="alert" id="warnToast" style="display: none;">
    <div class="weui-mask_transparent"></div>
    <div class="weui-toast">
      <i class="weui-icon-warn weui-icon_toast"></i>
      <p class="weui-toast__content">出错了！请联系工作人员！</p>
    </div>
  </div>
    <!-- loading toast -->
    <div v-show="showL">
      <div class="weui-mask_transparent"></div>
      <div class="weui-toast">
            <span class="weui-primary-loading weui-icon_toast">
              <span class="weui-primary-loading__dot"></span>
            </span>
        <p class="weui-toast__content">验券中</p>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'dyv',
    data(){
      return {
        show: false,
        token: "",
        code: "",
        showL: false,
        rid: sessionStorage.getItem('rid'),
      }
    },
    methods: {
      close(){
        this.show = false
      },

      valid(){
        const that = this
        this.show = false
        console.log(this.code)
        console.log(this.token)
        // that.showL = true
        let u =  that.$url + '/dy/valid?code='+that.code+"&token="+that.token+"&rid="+that.rid
        // setTimeout(function () {
        //   that.showL = false
          that.$axios.get(encodeURI(u).replace(/\+/g,'%2B'))
            .then(function(res) {
              console.log(res)
              if (res.data) {
                $("#toast").fadeIn(100)
                setTimeout(function () {
                  $("#toast").fadeOut(100);
                }, 2000);
              } else {
                $("#warnToast").fadeIn(100);
                setTimeout(function () {
                  $("#warnToast").fadeOut(100);
                }, 1000);
              }
            });
        // }, 2500)

      },


    }
  };
</script>

<style scoped>

</style>
